<template>
  <iframe
    :src="content.url"
    frameBorder="0"
    scrolling="no"
    :class="layoutClass"
    :style="layoutStyle"
  ></iframe>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "GoogleSlides",
  mixins: [layoutMixin],
  components: {},
  props: {
    content: Object,
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>
